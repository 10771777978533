@use '@angular/material' as mat;
@use 'sass:map';

@import '@angular/material/theming';

@include mat.core();

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


$kiene-green: (
    50: #D1E5D8,
    100: #DCDCDC,
    200: #479864,
    300: #479864,
    400: #197E3E,
    500: #7785A5,
    600: #026828,
    700: #025E24,
    800: #025320,
    900: #fff,
    A100: #B6174B,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (50: $black-87-opacity,
        100: white,
        200: #5EA578,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: #5EA578,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

$kiene-primary: mat.define-palette($kiene-green, 400, 200, 700);
$kiene-accent: mat.define-palette($kiene-green, 300);
$kiene-warn: mat.define-palette($kiene-green, A700);

$kiene-theme: mat.define-light-theme($kiene-primary, $kiene-accent, $kiene-warn);

$kiene-typography: mat.define-typography-config($font-family: 'Roboto');
//$kiene-typography: mat.define-typography-config($font-family: 'Montserrat');
//@include mat-core($kiene-typography);
@include angular-material-theme($kiene-theme);

$accent: map.get($kiene-accent, 300);
$primary: map.get($kiene-green, 400);
$warn: map.get($kiene-green, A400);

::ng-deep body {
    //font-family: 'Source Sans Pro', sans-serif;
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3 {
    color: $primary;
}

h3, h4 {
    margin: 16px 0;
}

.kiene-headline {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    color: $primary;
}

h3,
h4 {
    margin: 16px 0;
}

.kiene-headline {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 2em;
    color: $primary;
}

.primary-color {
    color: $primary;
}

.kiene-content-page {
    padding: 12px;
}

.kiene-table-header {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.one-row {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
}

.one-column {
    -ms-flex-align: start;
    align-items: flex-start;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    padding: 0 0;
    position: relative;
    justify-content: space-between;
    width: 100%;
}

.column-spacer {
    flex: 1 0 auto;
}

.kiene-page-header {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.flex-grid {
    display: flex;

    &.flex-column {
        flex-direction: column;
    }
}

.grid {
    display: grid;

    &.grid-1-rows {
        grid-template-rows: 1fr;
    }

    &.grid-2-rows {
        grid-template-rows: 1fr 1fr;
    }

    &.grid-3-rows {
        grid-template-rows: 1fr 1fr 1fr;
    }

    &.grid-1-columns {
        grid-template-columns: 1fr;
    }

    &.grid-2-columns {
        grid-template-columns: 1fr 1fr;
    }

    &.grid-3-columns {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.gap-10 {
        gap: 10px;
    }
}

mat-row {
    cursor: pointer;
}

.kiene-color {
    color: $primary;
}

.table-add-form {
    padding: 15px;
    border: 1px solid $primary;
    margin-bottom: 15px;
}

.table-add-form-field {
    padding: 5px;
}

.snackbar-alert {
    background-color: $warn;
    z-index: 10000;
    font-weight: bold;
    font-size: 4em;
}

.snackbar-hint {
    background-color: #ffc000;
    color: #000000;
    z-index: 10000;


}

.alert {
    color: $warn;
    border: 1px solid $warn;
    border-radius: 5px;
    background-color: rgba(213, 0, 0, 0.1);
    padding: 12px;
}

.snackbar-ok {
    background-color: $primary;
    z-index: 10000;
}


.white-color {
    color: #fff;
}

.sheet-item:hover {
    background-color: $accent;
}

.kiene-loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kiene-dialog-loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kiene-spacer,
.spacer {
    flex: 1 1 auto;
}


.kiene-form-field {
    padding-right: 10px;
    width: 45%;
}

.kiene-link {
    cursor: pointer;
    color: $primary;
}

.kiene-inline-link {
    color: $primary;
}

.pdf-dialog {
    width: 80vw;
}

.phito-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
}

.phito-table > thead > tr > th {
    text-align: left;
    color: #AAA;
    font-weight: bold;
    font-size: 12px;
}

.phito-table > tbody > tr > td {
    border-bottom: 1px solid #AAA;
    padding: 6px 6px 6px 0;
}

.phito-table > tbody > tr > td:first-child {
    color: #AAA;
}


.mat-column-artikel_id {
    max-width: 100px;
}

.mat-column-apotheke,
.mat-column-lager,
.mat-column-wagen,
.mat-column-ausgang,
.mat-column-summe,
.mat-column-einheit {
    max-width: 150px;
}

.phito-status-badge {
    color: $accent;
    border: 1px solid $accent;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.phito-notification-badge {
    color: $accent;
    border: 1px solid $accent;
    border-radius: 1px;
    padding: 5px 10px;
}

.mat-progress-bar-fill:after {
    // background-color: #a846a0 !important;
    //background-color: #ff006e !important;
    background-color: $accent;
}

.mat-option:hover:not(.mat-option-disabled):not(.mat-selected) {
    background: $accent;
    color: white !important;
}

.mat-option.mat-active:not(.mat-selected) {
    background: $accent !important;
    color: white !important;
}

.filter_menu {
    max-width: 1000px !important;
}

.cdk-overlay-pane {
    &.mat-tooltip-panel {
        .mat-tooltip {
            color: white;
            background-color: #197E3E;
            border: 1px solid #777;
            border-radius: 5px;
            font-size: 1.2em;
            padding: 10px;
        }
    }
}


.panel-class {
    min-width: 600px !important;
}

.hinweis-text {
    font-size: 0.9rem;
    color: #777;
    padding-top: 12px;
    text-align: justify;
}

.no-padding-margin-mat-form-field {
    .mat-form-field-flex {
        padding-top: 0;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-underline {
        bottom: 0;
    }
}

.mat-form-field--white {

    // mat-icon-stepper selected color change
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: white !important;
    }

    //input outline color
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: white !important;
    }

    //mat-input focused color
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: white !important;
    }

    // mat-input error outline color
    .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
        color: white !important;
    }

    // mat-input carent color
    .mat-input-element {
        caret-color: white !important;
    }

    // mat-input error carent color
    .mat-form-field-invalid .mat-input-element,
    .mat-warn .mat-input-element {
        caret-color: white !important;
    }

    // mat-label normal state style
    .mat-form-field-label {
        color: white !important;
        // color: $mainColor!important;
    }

    // mat-label focused style
    .mat-form-field.mat-focused .mat-form-field-label {
        color: white !important;
    }

    // mat-label error style
    .mat-form-field.mat-form-field-invalid .mat-form-field-label {
        color: white !important;
    }
}

.multiline-mat-option.mat-option,
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
    white-space: normal !important;
    line-height: normal !important;
}

.calendar-entry-disabled {
    background-color: #eee;
    pointer-events: none;
    opacity: 0.5;
    border-color: #e1e1e1;
}

.calendar-entry-start-day {
    border-left: solid 1px #e1e1e1 !important;
}

.calendar-entry-hidden {
    background-color: transparent;
    pointer-events: none;
    opacity: 0;
    border-color: transparent;
}

.calender-entry-weekend {
    background-color: #fafafa;
}

.mat-menu--desktop-gruppe-details.mat-menu-panel {
    min-width: 17vw !important;
}

.icon-badge > .mat-badge-content {
    font-family: 'Material Icons', serif;
    margin-top: 10px;
    margin-right: 5px;
    background-color: transparent;
    color: $primary;
    font-size: 1.25em;
}


.mat-dialog-no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.mat-dialog-no-padding .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
}

.hoverable {
    cursor: auto;
}

.hoverable:hover {
    cursor: pointer;
}

.info-box {
    display: flex;
    justify-content: space-between;
    border: 1px solid $primary;
    background-color: mat.get-color-from-palette($kiene-green, 300, .3);
    border-radius: 6px;
    padding: 12px;
    margin: 12px 0;
}

.info-box::before {
    content: url("./assets/info_black_24dp.svg");
    width: 40px;
}

